@font-face {
  font-family: "tom-icons";
  font-display: block;
  src: url("https://tom.mlcdn.com.br/icons/1.32.0/font/tom-icons.ttf?4a29f62226382f28ebf43ff5e82c042b") format("truetype"),
url("https://tom.mlcdn.com.br/icons/1.32.0/font/tom-icons.woff?4a29f62226382f28ebf43ff5e82c042b") format("woff"),
url("https://tom.mlcdn.com.br/icons/1.32.0/font/tom-icons.woff2?4a29f62226382f28ebf43ff5e82c042b") format("woff2");
}

  .ti {
    display: inline-flex;
  }

.ti:before {
  font-family: tom-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  min-width: 1em;
  min-height: 1em;
}

.ti.ti-access-time:before {
  content: "\f101";
}
.ti.ti-account-circle:before {
  content: "\f102";
}
.ti.ti-add:before {
  content: "\f103";
}
.ti.ti-arrow-back:before {
  content: "\f104";
}
.ti.ti-arrow-down-left:before {
  content: "\f105";
}
.ti.ti-arrow-down-right:before {
  content: "\f106";
}
.ti.ti-arrow-downward:before {
  content: "\f107";
}
.ti.ti-arrow-forward:before {
  content: "\f108";
}
.ti.ti-arrow-upward:before {
  content: "\f109";
}
.ti.ti-audio-up:before {
  content: "\f10a";
}
.ti.ti-bank-slip:before {
  content: "\f10b";
}
.ti.ti-calendar-today:before {
  content: "\f10c";
}
.ti.ti-category-af:before {
  content: "\f10d";
}
.ti.ti-category-am:before {
  content: "\f10e";
}
.ti.ti-category-ar:before {
  content: "\f10f";
}
.ti.ti-category-au:before {
  content: "\f110";
}
.ti.ti-category-bb:before {
  content: "\f111";
}
.ti.ti-category-br:before {
  content: "\f112";
}
.ti.ti-category-bs:before {
  content: "\f113";
}
.ti.ti-category-cf:before {
  content: "\f114";
}
.ti.ti-category-ci:before {
  content: "\f115";
}
.ti.ti-category-cj:before {
  content: "\f116";
}
.ti.ti-category-cm:before {
  content: "\f117";
}
.ti.ti-category-co:before {
  content: "\f118";
}
.ti.ti-category-cp:before {
  content: "\f119";
}
.ti.ti-category-cr:before {
  content: "\f11a";
}
.ti.ti-category-de:before {
  content: "\f11b";
}
.ti.ti-category-ea:before {
  content: "\f11c";
}
.ti.ti-category-ed:before {
  content: "\f11d";
}
.ti.ti-category-ep:before {
  content: "\f11e";
}
.ti.ti-category-es:before {
  content: "\f11f";
}
.ti.ti-category-et:before {
  content: "\f120";
}
.ti.ti-category-fj:before {
  content: "\f121";
}
.ti.ti-category-fm:before {
  content: "\f122";
}
.ti.ti-category-fs:before {
  content: "\f123";
}
.ti.ti-category-ga:before {
  content: "\f124";
}
.ti.ti-category-im:before {
  content: "\f125";
}
.ti.ti-category-in:before {
  content: "\f126";
}
.ti.ti-category-li:before {
  content: "\f127";
}
.ti.ti-category-md:before {
  content: "\f128";
}
.ti.ti-category-me:before {
  content: "\f129";
}
.ti.ti-category-mo:before {
  content: "\f12a";
}
.ti.ti-category-ms:before {
  content: "\f12b";
}
.ti.ti-category-na:before {
  content: "\f12c";
}
.ti.ti-category-pa:before {
  content: "\f12d";
}
.ti.ti-category-pe:before {
  content: "\f12e";
}
.ti.ti-category-pf:before {
  content: "\f12f";
}
.ti.ti-category-pi:before {
  content: "\f130";
}
.ti.ti-category-re:before {
  content: "\f131";
}
.ti.ti-category-rg:before {
  content: "\f132";
}
.ti.ti-category-sa:before {
  content: "\f133";
}
.ti.ti-category-se:before {
  content: "\f134";
}
.ti.ti-category-tb:before {
  content: "\f135";
}
.ti.ti-category-te:before {
  content: "\f136";
}
.ti.ti-category-tf:before {
  content: "\f137";
}
.ti.ti-category-ud:before {
  content: "\f138";
}
.ti.ti-chat-bubble-outline:before {
  content: "\f139";
}
.ti.ti-check-circle:before {
  content: "\f13a";
}
.ti.ti-check-magalu-indica:before {
  content: "\f13b";
}
.ti.ti-check:before {
  content: "\f13c";
}
.ti.ti-chevron-down:before {
  content: "\f13d";
}
.ti.ti-chevron-left:before {
  content: "\f13e";
}
.ti.ti-chevron-right:before {
  content: "\f13f";
}
.ti.ti-chevron-up:before {
  content: "\f140";
}
.ti.ti-close:before {
  content: "\f141";
}
.ti.ti-compra-internacional:before {
  content: "\f142";
}
.ti.ti-credit-card:before {
  content: "\f143";
}
.ti.ti-cupom:before {
  content: "\f144";
}
.ti.ti-customer-contact:before {
  content: "\f145";
}
.ti.ti-delete:before {
  content: "\f146";
}
.ti.ti-delivery-success:before {
  content: "\f147";
}
.ti.ti-delivery-warning:before {
  content: "\f148";
}
.ti.ti-discount:before {
  content: "\f149";
}
.ti.ti-entrega-2-dias:before {
  content: "\f14a";
}
.ti.ti-epoca:before {
  content: "\f14b";
}
.ti.ti-error-outline:before {
  content: "\f14c";
}
.ti.ti-exchange:before {
  content: "\f14d";
}
.ti.ti-facebook:before {
  content: "\f14e";
}
.ti.ti-favorite-border:before {
  content: "\f14f";
}
.ti.ti-favorite:before {
  content: "\f150";
}
.ti.ti-file-download:before {
  content: "\f151";
}
.ti.ti-file-upload:before {
  content: "\f152";
}
.ti.ti-filter-list:before {
  content: "\f153";
}
.ti.ti-guarantee-filled:before {
  content: "\f154";
}
.ti.ti-guarantee:before {
  content: "\f155";
}
.ti.ti-help-outline:before {
  content: "\f156";
}
.ti.ti-highlight-off:before {
  content: "\f157";
}
.ti.ti-home:before {
  content: "\f158";
}
.ti.ti-info:before {
  content: "\f159";
}
.ti.ti-instagram:before {
  content: "\f15a";
}
.ti.ti-inventory:before {
  content: "\f15b";
}
.ti.ti-ios-share:before {
  content: "\f15c";
}
.ti.ti-linkedin:before {
  content: "\f15d";
}
.ti.ti-list:before {
  content: "\f15e";
}
.ti.ti-loader-color-full:before {
  content: "\f15f";
}
.ti.ti-loader-color-less:before {
  content: "\f160";
}
.ti.ti-local-mall:before {
  content: "\f161";
}
.ti.ti-local-offer-outline:before {
  content: "\f162";
}
.ti.ti-local-shipping-filled:before {
  content: "\f163";
}
.ti.ti-local-shipping:before {
  content: "\f164";
}
.ti.ti-luiza-card:before {
  content: "\f165";
}
.ti.ti-magalu-recommend:before {
  content: "\f166";
}
.ti.ti-magalu:before {
  content: "\f167";
}
.ti.ti-mail:before {
  content: "\f168";
}
.ti.ti-menu:before {
  content: "\f169";
}
.ti.ti-more-horiz:before {
  content: "\f16a";
}
.ti.ti-more-vert:before {
  content: "\f16b";
}
.ti.ti-my-location:before {
  content: "\f16c";
}
.ti.ti-netshoes-circle:before {
  content: "\f16d";
}
.ti.ti-notifications:before {
  content: "\f16e";
}
.ti.ti-open-in-new:before {
  content: "\f16f";
}
.ti.ti-package-alert:before {
  content: "\f170";
}
.ti.ti-pedido:before {
  content: "\f171";
}
.ti.ti-person:before {
  content: "\f172";
}
.ti.ti-pix:before {
  content: "\f173";
}
.ti.ti-place:before {
  content: "\f174";
}
.ti.ti-play-circle:before {
  content: "\f175";
}
.ti.ti-privacy-policy:before {
  content: "\f176";
}
.ti.ti-receipt:before {
  content: "\f177";
}
.ti.ti-regulatory:before {
  content: "\f178";
}
.ti.ti-remove:before {
  content: "\f179";
}
.ti.ti-reply-all:before {
  content: "\f17a";
}
.ti.ti-search:before {
  content: "\f17b";
}
.ti.ti-service-baby-shower:before {
  content: "\f17c";
}
.ti.ti-service-consortium:before {
  content: "\f17d";
}
.ti.ti-service-control-plan:before {
  content: "\f17e";
}
.ti.ti-service-emp-magalu:before {
  content: "\f17f";
}
.ti.ti-service-gold-client:before {
  content: "\f180";
}
.ti.ti-service-house-security:before {
  content: "\f181";
}
.ti.ti-service-luizaseg:before {
  content: "\f182";
}
.ti.ti-service-marketplace:before {
  content: "\f183";
}
.ti.ti-service-marriage:before {
  content: "\f184";
}
.ti.ti-service-partner:before {
  content: "\f185";
}
.ti.ti-service-smart-house:before {
  content: "\f186";
}
.ti.ti-settings:before {
  content: "\f187";
}
.ti.ti-share:before {
  content: "\f188";
}
.ti.ti-shopping-bag-fast:before {
  content: "\f189";
}
.ti.ti-shopping-bag-filled:before {
  content: "\f18a";
}
.ti.ti-shopping-bag:before {
  content: "\f18b";
}
.ti.ti-star-border:before {
  content: "\f18c";
}
.ti.ti-star-half-filled:before {
  content: "\f18d";
}
.ti.ti-star-half:before {
  content: "\f18e";
}
.ti.ti-star:before {
  content: "\f18f";
}
.ti.ti-support-agent:before {
  content: "\f190";
}
.ti.ti-thumb-up:before {
  content: "\f191";
}
.ti.ti-tiktok:before {
  content: "\f192";
}
.ti.ti-twitter:before {
  content: "\f193";
}
.ti.ti-verified-user:before {
  content: "\f194";
}
.ti.ti-warning-amber:before {
  content: "\f195";
}
.ti.ti-whatsapp:before {
  content: "\f196";
}
.ti.ti-youtube:before {
  content: "\f197";
}
.ti.ti-zattini:before {
  content: "\f198";
}
